.highlighted-1 {
    color: #0d172d;
    background-color: #ffc801;
    font-family: font-bold;
    padding: 1px 6px 0 6px;
}
.highlighted-actuality {
    color: #ffffff;
    background-color: #ff7225;
    font-family: font-bold;
    padding: 1px 6px 0 6px;
}
.highlighted-2 {
    color: greenyellow;
    font-weight: 800;
    text-decoration: dashed;
}