body {
    font-family: 'Roboto', sans-serif;
    background-color: #1e3966;
}
#root {
    height: 100%;
}
#logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
}
#MainMenuSider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
}
#MainMenuSider,
#MainMenuSider .ant-menu {
    background-color: #1e3966;
    user-select: none;
    -webkit-user-select: none;
    -webkit-app-region: drag;
}
#MainMenuSider .ant-menu-vertical,
#MainMenuSider .ant-menu-vertical-left {
    border-right: none;
    width: 100%;
}
#MainMenuSider .ant-menu .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.25);
}
#MainMenuSider .ant-menu-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
}
#MainMenuSider .ant-menu-title-content {
    color: #ffffff;
    font-size: 1.5em;
    line-height: 0.8;
}
#MainMenuSider .ant-menu-title-content small {
    font-size: 0.8rem;
    margin-left: 1.75rem;
}
#MainMenuSider .yellow-menu .ant-menu-title-content {
    color: #ffc300 !important;
}
#MainMenuSider .ant-menu-item-selected > :before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(255, 255, 255, 0.95);
    position: absolute;
    right: 0;
    top: 11px;
}
#MainMenuSider .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ant-picker-panel-container {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.08), 0 4px 8px 4px rgba(0, 0, 0, 0.05);
    padding: 0.2em;
}
.ant-picker-dropdown {
    z-index: 1000;
}
.ant-layout,
.ant-layout-sider {
    background-color: unset;
}
.menu-footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 25px;
    width: 100%;
    color: white;
}
#applayout {
    background-color: #1e3966;
}
#applayout > .ant-layout-content {
    margin-left: 220px;
    border-top-left-radius: 12px;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1;
    height: 100vh;
}
::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar:horizontal {
    height: 12px;
}
::-webkit-scrollbar-track {
    border-radius: 12px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: rgba(241, 241, 241, 0.35);
    margin: 1px 0;
}
::-webkit-scrollbar-track:horizontal {
    height: 12px;
    margin: 0 1px;
}
::-webkit-scrollbar-track:hover {
    background-color: rgba(241, 241, 241, 0.5);
}
::-webkit-scrollbar-thumb {
    min-height: 30px;
    border-radius: 12px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: rgba(102, 157, 205, 0.4);
}
::-webkit-scrollbar-thumb:horizontal {
    min-width: 30px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(102, 157, 205, 0.8);
}

.ant-image-img {
    width: unset;
    height: auto;
    vertical-align: middle;
}
