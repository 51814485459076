.departueListLayout {
	 margin-top: 2rem;
	 margin-bottom: 2rem;
	 font-weight: 800;
}
 .indulasCol {
	 min-width: 45px;
	 display: flex;
	 justify-content: center;
}
 .isNotHeader .indulasCol {
	 font-weight: 600;
	 font-size: 1.2rem;
}
 .jaratnevCol {
	 min-width: 120px;
	 font-weight: 600;
}
 .isNotHeader .jaratnevCol {
	 font-size: 1.2rem;
}
 .publishedCol {
	 min-width: 60px;
	 display: flex;
	 justify-content: center;
}
 .gateCol {
	 min-width: 60px;
	 display: flex;
	 justify-content: center;
	 padding-right: 1rem;
}
 :global(.departureListItem:not(.first)) {
	 margin-top: 1.5rem;
}
 :global(.departureListItem:not(.last)) {
	 border-bottom: 1px solid #e4e4e4;
	 padding-bottom: 1rem;
}
 .dailyDeparture {
	 margin-top: -5px;
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;
	 padding: 5px;
	 border-radius: 4px;
	 border: 1px solid #e6e6e6;
	 margin-right: 5px;
}
 .dailyDepartureEmpty {
	 margin-top: -5px;
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;
	 border-radius: 4px;
	 background-color: #f1f1f1;
	 border: 1px dashed lightgray;
	 margin-right: 5px;
	 width: 89px;
	 height: 57px;
}
 .dailyDepartureEmpty span {
	 color: grey;
}
 .dailyDepartureEmpty:hover {
	 cursor: pointer;
	 background-color: #fafafa;
}
 