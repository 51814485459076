.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    z-index: 1000;
}

.row-dragging td {
    padding: 10px;
}

.row-dragging .drag-visible {
    visibility: visible;
    cursor: grabbing;
    cursor: -webkit-grabbing;
}
