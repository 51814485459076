.screenItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 1.2em;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    background-color: white;
}
.screenItem:hover {
    background-color: #f7f7f7;
}
.screenItem:not(:first-of-type) {
    margin-top: 1rem;
}
.screenName {
    font-size: 1.3em;
    font-weight: 600;
}
.gate {
    font-size: 1em;
    font-weight: 600;
    padding-right: 2em;
    justify-content: flex-end;
}
.infoContent {
    font-size: 1em;
    font-weight: 400;
    flex: 1;
    padding-right: 2em;
    justify-content: flex-start;
}
.macModelNumber {
    font-size: 1em;
    font-weight: 600;
    flex: 1;
    padding-right: 2em;
    justify-content: flex-end;
}
.statusColorOnline {
    color: #52c41a;
}
.statusColorOffline {
    color: #ff4d4f;
}
