/* FONTOK */

@font-face {
  font-family: "font-black";
  src: url("./MyriadPro-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-bold";
  src: url("./MyriadPro-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "font-condensed";
  src: url("./MyriadPro-Condensed.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-condensed-bold";
  src: url("./MyriadPro-BoldCondensed.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-condensed-light";
  src: url("./MyriadPro-LightCondensed.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "font-semi-condensed";
  src: url("./MyriadPro-SemiCondensed.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-semi-condensed-bold";
  src: url("./MyriadPro-BoldSemiCondensed.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-semi-condensed-light";
  src: url("./MyriadPro-LightSemiCondensed.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "font-regular";
  src: url("./MyriadPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-italic";
  src: url("./MyriadPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-light";
  src: url("./MyriadPro-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-semibold";
  src: url("./MyriadPro-Semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* 
@font-face {
  font-family: 'diavlo';
  src: url('./diavlomed.eot');
  src: url('./diavlomed.eot?#iefix') format('embedded-opentype'), url('./diavlomed.woff') format('woff'), url('./diavlomed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */
