.previewContainer {
    background: url('./images/penztar-bg-empty.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10%;
    margin-bottom: 20px;
    font-size: 2rem;
    color: white;
    font-family: font-regular;
}
.previewContainer.actuality {
    background-position: bottom;
    font-size: 1rem;
    padding: 5% 0;
    justify-content: flex-end;
}

.actualityWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    background-color: #ffdf38;
    width: 90%;
}
.previewTitle {
    font-size: 2em;
    font-family: font-bold;
    line-height: 1.2em;
    margin-bottom: 0.5em;
}
.actualityWrapper .previewTitle {
    text-align: center;
    text-transform: uppercase;
    font-size: 2.6em;
    color: #ffffff;
    background-color: #ff7225;
    line-height: 1.1em;
    padding: 8px 5px 2px 5px;
    margin-bottom: 0;
    border-bottom: 2px solid #fff;
}
.actualityWrapper .previewTitle .dateOfToday {
    color: #0d172d;
    font-family: font-light;
    font-size: 1em;
}
.previewBody {
    font-size: 1.1em;
}

.actualityWrapper .previewBody {
    color: #0d172d;
    font-size: 1.2em;
    padding: 1em;
    font-family: font-semi-condensed;
}

.previewContainer p {
    margin: 0;
}
